import React from "react";
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  SearchState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  SearchPanel,
  PagingPanel
} from "@devexpress/dx-react-grid-bootstrap4";

export default class KGrid extends React.Component {
  render() {
    if (!this.props.filteredData || this.props.filteredData.length === 0) {
      return <div>No data</div>;
    }
    return (
      <Grid
        rows={this.props.filteredData}
        getRowId={this.props.rowIdGenerator}
        columns={this.props.cols}
      >
        <SortingState defaultSorting={this.props.sortInfo} />
        {this.props.selectionEnabled && (
          <SelectionState
            selection={this.props.selection}
            onSelectionChange={this.props.changeSelection}
          />
        )}
        <PagingState
          defaultCurrentPage={this.props.page ? this.props.page.currPage : 0}
          pageSize={this.props.page ? this.props.page.size : 50}
        />
        <SearchState />
        <IntegratedFiltering />
        <IntegratedSorting />
        {this.props.selectionEnabled && <IntegratedSelection />}

        <IntegratedPaging />

        {this.props.columnExtensions && (
          <Table columnExtensions={this.props.columnExtensions} />
        )}
        {!this.props.columnExtensions && <Table />}

        {this.props.selectionEnabled && <TableSelection selectByRowClick />}
        <TableHeaderRow showSortingControls />

        <Toolbar />
        <SearchPanel />
        <PagingPanel />
      </Grid>
    );
  }
}
