import React, { Component } from "react";
import { Progress, Error } from "../Helpers";

import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import "./CardForm.css";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

class _CardForm extends Component {
  state = {
    errorMessage: ""
  };

  componentWillReceiveProps(props) {
    if (props.resetCardInfo !== this.props.resetCardInfo) {
      this.setState({
        errorMessage: undefined,
        valid: true,
        submitting: false,
        err: undefined
      });
    }
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message, valid: false });
    } else {
      this.setState({ errorMessage: "", valid: true });
    }
  };

  handleSubmit = evt => {
    evt.preventDefault();
    if (!this.state.valid) {
      return;
    }
    if (this.props.onPaymentCallbacks) {
      this.props.onPaymentCallbacks.start();
    }
    this.setState({ submitting: true });
    if (this.props.stripe) {
      this.props.stripe.createToken().then(res => {
        if (res.error) {
          this.setState({ submitting: false, err: res.error.message });
        } else {
          this.props.handleResult(res);
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div className="CardDemo">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <label>
            Card Details
            <CardElement
              onChange={this.handleChange}
              {...createOptions()}
              className="form-element"
            />
          </label>
          <div className="text-danger" role="alert">
            {this.state.errorMessage}
          </div>
          <button
            className="btn btn-primary"
            disabled={
              !this.state.valid ||
              !this.props.allowedToPay ||
              this.state.submitting
            }
          >
            Pay
          </button>
          {this.state.submitting && <Progress />}
          {!this.state.submitting && this.state.err && (
            <Error err={{ msg: this.state.err }} />
          )}
        </form>
      </div>
    );
  }
}

const StripeCardForm = injectStripe(_CardForm);

export default class CardForm extends Component {
  render() {
    return (
      <StripeProvider apiKey={this.props.stripePublicKey}>
        <Elements>
          <StripeCardForm
            handleResult={this.props.handleResult}
            allowedToPay={this.props.allowedToPay}
            onPaymentStart={this.props.onPaymentStart}
            resetCardInfo={this.props.resetCardInfo}
          />
        </Elements>
      </StripeProvider>
    );
  }
}
