import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./KDatePicker.css";

const resetTime = d => {
  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
};

const getMaxDt = (dt, factor) => {
  let max = new Date(dt);
  if (factor) {
    let [num, unit] = factor.split(".");
    num = Number(num);
    switch (unit) {
      case "d":
        max.setDate(max.getDate() + num);
        break;
      case "w":
        max.setDate(max.getDate() + 7);
        break;
      case "m":
        max.setMonth(max.getMonth() + num);
        break;
      case "y":
        max.setFullYear(max.getFullYear() + num);
        break;
      default:
    }
    resetTime(max);
  }
  return max;
};

export default function KDatePicker(props) {
  const { title, titleType, ...dtProps } = props;
  if (!dtProps.dateFormat) {
    dtProps.dateFormat = dtProps.showTimeSelect
      ? "MMM d, yyyy h:mm aa"
      : "MMM d, yyyy";
  }
  if (!dtProps.selected) {
    dtProps.selected = new Date();
    resetTime(dtProps.selected);
  }

  if (!dtProps.className) {
    dtProps.className = "form-control";
  }

  dtProps.minDate = !dtProps.min ? dtProps.selected : dtProps.min;
  dtProps.maxDate = !dtProps.max
    ? null
    : getMaxDt(dtProps.minDate, dtProps.max);

  if (dtProps.showTimeSelect) {
    Object.assign(dtProps, {
      timeFormat: "HH:mm",
      timeIntervals: 10,
      timeCaption: "time"
    });
  }
  return (
    <div className="form-group">
      {titleType === "header" && <h3 htmlFor={dtProps.id}>{title}</h3>}
      {titleType !== "header" && <label htmlFor={dtProps.id}>{title}</label>}
      <DatePicker {...dtProps} />
    </div>
  );
}
