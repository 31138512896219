import Cache from "./Cache";

// let isServer = process.env.REACT_APP_SERVER === "true";
const server = window.location.host.indexOf("localhost") === 0 ? "http://localhost:8080/api" : "/api";

const isPostOrPut = method => method === "POST" || method === "PUT";

const commonHeaders = ({ method, contentType, tenant }) => {
  if (method === "OPTIONS") {
    return {};
  }
  tenant = tenant ? tenant : Cache.getTenant();
  let headers = {
    appsy: "Singh",
    org: tenant
  };
  if (isPostOrPut(method) && contentType !== CONTENT_TYPE_NONE) {
    headers["Content-Type"] = contentType ? contentType : CONTENT_TYPE_JSON;
  }
  let user = Cache.get("user");
  if (user) {
    headers["session"] = JSON.parse(user).sessionId;
  }
  return headers;
};

const execute = async ({
  method,
  api,
  data,
  customHeaders = {},
  contentType,
  tenant,
  hideProgress
}) => {
  if (method === "POST" && hideProgress !== true) {
    document.getElementById("mask").classList.remove("d-none");
  }

  let error = false;
  let headers = customHeaders
    ? Object.assign(
        {},
        commonHeaders({
          method,
          contentType,
          tenant
        }),
        customHeaders
      )
    : commonHeaders(method);
  let httpParams = {
    method: method,
    headers: headers
  };

  httpParams["body"] =
    headers[CONTENT_TYPE_HEADER] === CONTENT_TYPE_JSON
      ? JSON.stringify(data)
      : data;

  if (api.toLowerCase().indexOf("http") !== 0) {
    api = `${server}/${api}`;
  }

  try {
    let resp = await fetch(api, httpParams);

    error = !resp.ok;
    if (error) {
      if (resp.status === 401) {
        localStorage.clear();
        window.location = "/";
        return;
      }
      let err = {
        code: resp.status,
        msg:
          resp.statusText !== "" ? resp.statusText : "Something went wrong!!",
        error: error
      };
      throw err;
    }

    let respContentType = resp.headers.get("Content-Type");
    if (respContentType && respContentType.indexOf("application/json") > -1) {
      return resp.json();
    } else {
      return resp.text();
    }
  } catch (appErr) {
    throw appErr;
  } finally {
    if (method === "POST" && hideProgress !== true) {
      document.getElementById("mask").classList.add("d-none");
    }
  }
};

const CONTENT_TYPE_HEADER = "Content-Type";
const CONTENT_TYPE_JSON = "application/json";
const CONTENT_TYPE_PLAIN = "text/plain";
const CONTENT_TYPE_NONE = "none";

const Http = {
  postForm: async (api, data, customHeaders, tenant) => {
    let formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    return execute({
      method: "POST",
      api,
      data: formData,
      customHeaders,
      contentType: CONTENT_TYPE_NONE,
      tenant
    });
  },
  putJSON: async (api, data, customHeaders, tenant) => {
    return execute({
      method: "PUT",
      api,
      data,
      customHeaders,
      tenant
    });
  },
  postJSON: async (api, data, customHeaders, tenant) => {
    return execute({
      method: "POST",
      api,
      data,
      customHeaders,
      tenant
    });
  },
  postPlainText: async (api, data, customHeaders, tenant) => {
    return execute({
      method: "POST",
      api,
      data,
      customHeaders,
      contentType: CONTENT_TYPE_PLAIN,
      tenant
    });
  },
  del: async api => {
    return execute({
      method: "DELETE",
      api
    });
  },
  get: async (api, customHeaders, tenant) => {
    return execute({
      method: "GET",
      api,
      data: null,
      customHeaders,
      tenant
    });
  },
  upload: async file => {
    var data = new FormData();
    data.append("file", file);
    data.append("name", file.name);

    return execute({
      method: "post",
      api: "upload",
      data,
      CONTENT_TYPE_HEADER: CONTENT_TYPE_NONE
    });
  }
};
export default Http;
