const TENANT_KEY = "tenant";

export default {
  set: (key, val) => {
    localStorage.setItem(key, val);
  },
  get: key => localStorage.getItem(key),
  getTenant: () =>
    localStorage.getItem(TENANT_KEY)
      ? localStorage.getItem(TENANT_KEY)
      : "ddnj",
  setTenant: tenant => localStorage.setItem(TENANT_KEY, tenant)
};
