import Base64Util from "./Base64Util";
import React from "react";
import ReactDOM from "react-dom";
import Alert from "./Alert";

const modalHolder = document.getElementById("modalHolder");

const showAlert = ({ message, okCb, reload, sev, type, title }) => {
  title = title
    ? title
    : type === "yesNo"
    ? "Please Confirm"
    : sev === "general"
    ? "Done"
    : "Error";

  ReactDOM.render(
    <Alert
      title={title}
      text={message}
      sev={sev}
      type={type}
      cb={res => {
        ReactDOM.unmountComponentAtNode(modalHolder);
        if (okCb) {
          if (type === "yesNo") {
            if (res && res === true) {
              okCb();
            }
          } else {
            okCb();
          }
        }
      }}
      reloadPage={reload}
    />,
    modalHolder
  );
};

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const Util = {
  warningAlert: (message, okCb) => {
    showAlert("warning", message, okCb);
  },
  alert: (message, okCb, reload) => {
    showAlert({ message, okCb, reload, sev: "general" });
  },
  errorAlert: (message, okCb, reload) => {
    showAlert({ message, okCb, reload, sev: "error" });
  },
  confirm: (message, cb) => {
    showAlert({
      message,
      okCb: cb,
      reload: false,
      sev: "general",
      type: "yesNo"
    });
  },
  prompt: (message, defVal) => {
    return window.prompt(message, defVal);
  },
  saveLocally: (key, val) => {
    if (
      val === null ||
      val.trim() === "" ||
      val === "undefined" ||
      typeof val === "undefined"
    ) {
      localStorage.removeItem(key);
      return;
    }
    localStorage.setItem(key, val);
  },
  deleteLocally: key => {
    localStorage.removeItem(key);
  },
  getLocal: key => {
    return localStorage.getItem(key);
  },
  formatDate: ts => {
    let d = new Date(ts);
    return `${
      Months[d.getMonth()]
    } ${d.getDate()}, ${d.getUTCFullYear()}, ${formatTime(d)}`;
  },
  getQueryParameter: (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  encodeBase64: str => Base64Util.encode(str),
  decodeBase64: str => Base64Util.decode(str)
};

export default Util;
