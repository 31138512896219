import React, {useState, useEffect} from "react";

export default function Flash({msg, autoHide, ttl}) {
  const cls = msg && msg.err ? "text-danger" : "text-success";
  const [text, setText] = useState('');

  useEffect(() => {
    const toDisp = typeof msg === "string" ? msg : msg.text;
    setText(toDisp);

    if(!autoHide && !msg.err) {
      setTimeout(() => setText(null), ttl || 10000);
    }
  }, [msg, autoHide, ttl]);

  
  return (
    <span className={`${cls} ml-3 align-middle`}>
      <em>{text}</em>
    </span>
  );
}
