import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Alert = props => {
  const [modal, setModal] = useState(true);

  const toggle = yes => {
    setModal(!modal);
    if (props.cb) {
      props.cb(yes);
    }
    if (props.reloadPage) {
      window.location.reload();
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
        <ModalBody>{props.text}</ModalBody>
        {props.type === "yesNo" && (
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                toggle(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              onClick={() => {
                toggle(true);
              }}
            >
              Yes
            </Button>
          </ModalFooter>
        )}

        {props.type !== "yesNo" && (
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Ok
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default Alert;
