import React, { useEffect } from "react";
import { Http } from "../../../Helpers/";
export default function Logout(props) {
  useEffect(() => {
    function logout() {
      Http.postForm("logout", {});
      localStorage.clear();
      window.location = "/";
    }
    logout();
  }, []);

  return <h3>Logging out...</h3>;
}
