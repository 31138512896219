import React, { useState } from "react";
import { Http, Util, Field, Cache } from "../../../Helpers/";
import { Link } from "react-router-dom";

import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";

function ResetPwd(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(undefined);
  const [phone, setPhone] = useState("");
  const [orgName, setOrgName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [showResetCode, setShowResetCode] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  function resetPwd() {
    if (
      code.trim() === "" ||
      password.trim() === "" ||
      password !== confirmPassword
    ) {
      Util.errorAlert(
        "Please make sure the passwords match. Phone, code and org name can't be blank!"
      );
      return;
    }
    setSubmitting(true);
    Http.postForm(
      "update-password",
      {
        phone: phone,
        resetCode: code,
        password
      },
      {},
      orgName
    )
      .then(() => {
        //redirect to login
        Util.alert(
          "Password has been reset successfuly. You'll be redirected to login page now",
          () => {
            setLoggedIn(true);
            window.location = "/#/login";
          }
        );
      })
      .catch(err => {
        console.log(err);
        Util.errorAlert(
          "Something went wrong! Please make sure the code matches the code we sent you in the text"
        );
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }
  function reqResetCode() {
    if (orgName.trim() === "" || phone.trim() === "") {
      Util.errorAlert("Phone and org name can't be blank!");
      return;
    }
    setSubmitting(true);
    Http.postForm("reset-password", { phone: phone }, {}, orgName)
      .then(() => {
        setShowResetCode(true);
        Util.alert(
          "We've texted you a reset code. Please enter that code and enter a new password"
        );
      })
      .catch(err => {
        console.log(err);
        Util.errorAlert("Something went wrong. Please try again later!");
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showResetCodeInput() {
    setShowResetCode(true);
  }

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="9" lg="7" xl="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form>
                  <h1>Reset Password</h1>
                  <Field
                    lbl="Phone #"
                    type="phone"
                    focus={true}
                    value={phone}
                    onChange={e => {
                      setPhone(e.target.value);
                    }}
                    fldIcon="icon-phone"
                    placeholder="Phone #"
                  />

                  <Field
                    lbl="Org Name"
                    type="orgName"
                    placeholder="Account Name"
                    value={orgName}
                    onChange={e => {
                      setOrgName(e.target.value);
                    }}
                    fldIcon="icon-briefcase"
                  />

                  {showResetCode && (
                    <div>
                      <Field
                        placeholder="Reset Code"
                        name="code"
                        value={code}
                        onChange={e => {
                          setCode(e.target.value);
                        }}
                        fldIcon="icon-bubble"
                      />

                      <Field
                        placeholder="Password"
                        name="pwd"
                        type="password"
                        value={password}
                        onChange={e => {
                          setPassword(e.target.value);
                        }}
                        fldIcon="icon-lock"
                      />
                      <Field
                        placeholder="Confirm Password"
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={e => {
                          setConfirmPassword(e.target.value);
                        }}
                        fldIcon="icon-lock"
                      />
                    </div>
                  )}

                  {showResetCode && (
                    <Button
                      type="button"
                      color="warning"
                      onClick={resetPwd}
                      disabled={submitting}
                      block
                    >
                      Reset Password
                    </Button>
                  )}

                  <Button
                    type="button"
                    color="warning"
                    disabled={submitting}
                    onClick={reqResetCode}
                    block
                  >
                    Request Reset Code
                  </Button>

                  {!showResetCode && (
                    <Button type="button" onClick={showResetCodeInput} block>
                      I have the Reset Code
                    </Button>
                  )}

                  <Col xs="12" className="text-center">
                    <Link to="/login">Login</Link>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPwd;
