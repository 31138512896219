import React, { useState } from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";

export default function Field(props) {
  const id = props.id ? props.id : props.name;

  let { fldIcon, grpCls, ...fldProps } = props;

  let fldCls = (props.className ? props.className + " " : "") + "form-control";
  fldCls = props.inline ? fldCls + " ml-3" : fldCls;
  let wrapperCls = props.inline ? "form-group form-inline" : "form-group";

  if (props.bare) {
    return (
      <div className={wrapperCls}>
        <label htmlFor={id}>{props.lbl}</label>
        <input {...fldProps} />
      </div>
    );
  }

  grpCls = grpCls ? grpCls : "mb-4";
  return (
    <InputGroup className={grpCls}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className={fldIcon}></i>
        </InputGroupText>
      </InputGroupAddon>
      <Input {...fldProps} />
      {props.button && (
        <Button
          className={props.button.cls}
          onClick={() => {
            props.button.ac();
          }}
        >
          {props.button.text}
        </Button>
      )}
    </InputGroup>
  );
}
