import React from "react";

export default function Error(props) {
  let textCls = props.txtClass ? props.txtClass : "text-danger";
  return (
    <span className={textCls}>
      {props.err ? props.err.msg : "Something went wrong!"}
    </span>
  );
}
